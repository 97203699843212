<template>
	<div class="main">
		<img class="bg" src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627522836-6102071492435.png" alt="">
		<div class="content">
			<div class="title">门禁情况</div>
			<div class="menjin">
				<div class="menjin-item" v-if="resData.length != 0">
					<div class="menjin-item-01">
						<div class="t01">{{resData[0].title}}</div>
						<div :class="[resData[0].state == '1' ? 't02' : 't03']"></div>
					</div>
					<div class="img1">
						<img src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627524230-61020c868fcaa.png" alt="">
					</div>
					<div class="state01">
						状态：<span :class="[resData[0].state == '1' ? 'isonline' : 'isonline2']">{{ resData[0].state == '1' ? '在线' : '离线' }}</span>
					</div>
					
				</div>
				<div class="menjin-item" v-if="resData.length != 0">
					<div class="menjin-item-01">
						<div class="t01">{{resData[1].title}}</div>
						<div :class="[resData[1].state == '1' ? 't02' : 't03']"></div>
					</div>
					<div class="img1">
						<img src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627524230-61020c868fcaa.png" alt="">
					</div>
					<div class="state01">
						状态：<span :class="[resData[1].state == '1' ? 'isonline' : 'isonline2']">{{ resData[1].state == '1' ? '在线' : '离线' }}</span>
					</div>
					
				</div>
			</div>
			<div class="title mTop">数据情况</div>
			<div class="dataBtn">
				<img class="jiaobiao" src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627526136-610213f804eb9.png" alt="">
				<div class="dataBtn-left">
					<img src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627525476-61021164f0c52.png" alt="">
				</div>
				<div class="dataBtn-right">
					<div class="dataBtn-right-txt">场馆各项数据实时可查</div>
					<el-button class="btn" round @click="lookDetail">查看详情</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/api/api'
export default {
	data(){
		return {
			resData: [],
		}
	},
	mounted() {
		// this.fnResize();
		// window.onresize = function () {
		//   this.fnResize();
		// }
		this.getState()
	},
	methods: {
		getState(){
			var _this = this
			api.getGuardState().then(r => {
				console.log("www", r)
				_this.resData = r.data.data.doors
			})
		},
		
		lookDetail(){
			this.$router.push({
			  name: 'databoard'
			})
		},
		fnResize() {
		  var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
		  if (deviceWidth >= 560) {
		      deviceWidth = 560;
		  }
		  var wr;
		  var rem;
		  if (deviceWidth == 375) {
		     rem = 100
		  } else {
		    wr=deviceWidth / 375;
		    rem=wr * 100;
		  }                     
		  document.documentElement.style.fontSize = rem + 'px';
		}
	}
}
</script>

<style scoped lang="less">
.main{
	background: #fff;
	min-height: 100%;
	position: relative;
	box-sizing: border-box;
	padding-bottom: 20px;
	.bg{
		width: 100%;
	}
}
.content{
	position: relative;
	// top: -64vw;
	margin-top: -57vw;
	width: 100%;
	box-sizing: border-box;
	padding: 0 30px;
	.title{
		font-family: PingFangSC-Medium;
		font-size: 5vw;
		color: rgba(0,0,0,0.85);
		letter-spacing: 0.58px;
		text-align: left;
	}
	.mTop{
		margin-top: 20px;
	}
	.menjin{
		display: flex;
		justify-content: space-between;
		margin-top: 5vw;
		.menjin-item{
			width: 47%;
			background: #FFFFFF;
			box-shadow: 0 4px 14px 0 rgba(191,188,183,0.36);
			border-radius: 10px;
			box-sizing: border-box;
			padding: 15px;
			.menjin-item-01{
				display: flex;
				align-items: center;
				
				justify-content: space-between;
				
				.t01{
					font-size: 3vw;
					color: #FFFFFF;
					background: #368374;
					padding: 4px 6px;
					border-radius: 4px;
				}
				.t02{
					width: 1.5vw;
					height: 1.5vw;
					background: #2FC25B;
					border-radius: 50%;
				}
				.t03{
					width: 1.5vw;
					height: 1.5vw;
					background: #E02020;
					border-radius: 50%;
				}
			}
			.img1{
				width: 50%;
				margin: auto;
				margin-top: 20px;
				img{
					width: 100%;
					display: block;
				}
			}
			.state01{
				font-family: PingFangSC-Regular;
				font-size: 3vw;
				color: rgba(0,0,0,0.45);
				letter-spacing: 0;
				margin-top: 20px;
				.isonline{
					color: #368374;
				}
				.isonline2{
					color: #E02020;
				}	
			}
		}
	}
	.dataBtn{
		display: flex;
		background: #FFFFFF;
		box-shadow: 0 4px 14px 0 rgba(191,188,183,0.36);
		border-radius: 10px;
		box-sizing: border-box;
		padding: 15px 20px;
		align-items: center;
		justify-content: space-between;
		font-size: 1px;
		position: relative;
		margin-top: 20px;
		.jiaobiao{
			position: absolute;
			width: 9vw;
			right: 0;
			top: 0;
		}
		.dataBtn-left{
			width: 40%;
			img{
				width: 100%;
				display: block;
			}
		}
		.dataBtn-right{
			font-size: 1vw;
			.dataBtn-right-txt{
				font-size: 3.5vw;
				margin-top: 6vw;
			}
			.btn{
				background: #B78B52; 
				color: #fff;
				width: 100%;
				margin-top: 2vw;
				height: 10vw;
				line-height: 10vw;
				font-size: 3vw;
				padding: 0;
			}
		}
	}
}
</style>
